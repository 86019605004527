<template>
  <div>
    <q-layout view="lHh lpr lFf">
      <q-page-container >
        <q-page >
          <div>
            <router-view/>
          </div>
        </q-page>
      </q-page-container>
    </q-layout>
  </div>
</template>

<script>
export default {
  name: 'emptyLayout'
}
</script>

<style scoped>

</style>
